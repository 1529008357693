.error-modal {
  margin: 100px auto;
  height: 350px;
  margin-left: 220px!important;
  @media (max-width: 768px) {
    height: 450px;
    margin-left: 0!important;
  }
  .dialog-title {
    text-align: center;
    padding: 10px;
    background-color: #ED5356;
    height: 45px;
    // width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .main-close-icon {
      cursor: pointer;
    }
  }
  .dialog-message-container {
    text-align: center;
    // min-height: 290px;
    min-width: 400px;
    // max-width: 400px;
    overflow: hidden;
    @media (max-width: 768px) {
      max-width: 265px;
      min-width: 265px;
      // height: 225px;
      // max-height: 225px;
    }
    .ok-button {
      border: 2px solid #ED5356;
      color: #ED5356;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 20px auto;
    }
    .special-error-message {
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
    .close-icon-center {
      // margin: 10px auto;
      .error-modal-header-text {
        font-size: 35px;
        color: #ED5356;
      }
    }
  }
}


.new-modal-box {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5a5a5a78;
  z-index: 999;
  backdrop-filter: blur(3px);

  .new-modal-container {
    border-radius: 8px;
    border: 1px solid #E9E9E9;

    background: #FFF;
    padding: 26px 0;
    width: 424px;
    max-width: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .new-modal-icon {
  
    }
  
    .new-modal-title {
      color: #FF6565;

      font-family: 'Noto Sans JP'!important;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.264px;
      margin-top: 16px;
    }

    .new-modal-subtitle {
      color: #1E1E1E;
      text-align: center;
      font-family: 'Noto Sans JP'!important;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.176px;
      margin-top: 11px;
      white-space: pre-wrap;
    }

    .new-modal-cta {
      margin-top: 27px;
      border-radius: 8px;
      background: #33A8ED;
      padding: 10px 24px;
      text-align: center;
      color: #FFF;
      font-family: "Noto Sans JP"!important;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      cursor: pointer;
    }
  }
}