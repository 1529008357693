@charset "UTF-8";
.rbc-time-header {
  margin-right: 0 !important;
}

.rbc-time-view {
  border-radius: 8px !important;
  border: 1.5px solid #959595 !important;
  border-bottom: 0 !important;
}

.rbc-header {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1E1E1E;
  font-family: "Noto Sans JP" !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.176px;
  border-bottom: none !important;
}
@media (max-width: 768px) {
  .rbc-header {
    font-size: 18px;
  }
}

.rbc-header + .rbc-header {
  border-left: 1.5px solid #959595 !important;
}

.rbc-time-content {
  border-top: 1.5px solid #959595 !important;
}
@media (max-width: 768px) {
  .rbc-time-content {
    border-top: 0 !important;
  }
}

.rbc-event {
  border-radius: 8px;
  height: 48px !important;
  margin-top: 4px;
  font-family: "Noto Sans JP" !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.176px;
}
@media (max-width: 768px) {
  .rbc-event {
    margin-top: 10px;
  }
}

.rbc-timeslot-group {
  border-bottom: 1.5px solid #959595 !important;
  min-height: 56px !important;
  max-height: 56px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .rbc-timeslot-group {
    min-height: 68px !important;
    max-height: 68px !important;
  }
}

.rbc-time-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1E1E1E;
  font-family: "Noto Sans JP" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  width: 87px;
  letter-spacing: -0.176px;
}

.rbc-day-slot .rbc-events-container {
  margin: 0px 8px !important;
  padding: 0 8px !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.rbc-time-content > * + * > * {
  border-left: 1.5px solid #959595 !important;
}

.rbc-time-header.rbc-overflowing {
  border-right: none !important;
}

.rbc-time-header-content {
  border-left: 1.5px solid #959595 !important;
}

.rbc-allday-cell {
  display: none;
}

.add-interview-times-container {
  display: block;
  margin-left: 220px;
  width: calc(100% - 220px);
  margin-top: 180px;
}
.add-interview-times-container .BaseTable__row .BaseTable__row-cell-text {
  height: 100%;
}
@media (max-width: 768px) {
  .add-interview-times-container {
    width: 100%;
    margin-left: 0px;
    overflow: auto;
    margin-top: 75px;
  }
}
.add-interview-times-container .add-interview-times-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}
@media (max-width: 375px) {
  .add-interview-times-container .add-interview-times-inner {
    width: 350px;
  }
}
.add-interview-times-container .add-interview-times-inner .main-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .main-info-container {
    padding: 0 8px;
  }
}
.add-interview-times-container .add-interview-times-inner .main-info-container .slots-container {
  border-radius: 8px;
  border: 2px solid #33A8ED;
  color: #08105D;
  text-align: center;
  font-family: "Noto Sans JP" !important;
  font-weight: 700;
  line-height: 150%;
  padding: 10px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .main-info-container .slots-container {
    padding: 4px 10px;
  }
}
.add-interview-times-container .add-interview-times-inner .main-info-container .slots-container .slots-value {
  font-size: 24px;
  letter-spacing: -0.264px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .main-info-container .slots-container .slots-value {
    font-size: 20px;
    letter-spacing: -0.22px;
  }
}
.add-interview-times-container .add-interview-times-inner .main-info-container .slots-container .slots-subvalue {
  font-size: 16px;
  letter-spacing: -0.176px;
  text-wrap: nowrap;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .main-info-container .slots-container .slots-subvalue {
    font-size: 12px;
    letter-spacing: -0.132px;
  }
}
.add-interview-times-container .add-interview-times-inner .main-info-container .main-info-text {
  color: #1E1E1E;
  font-family: "Noto Sans JP" !important;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.264px;
  text-align: left;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .main-info-container .main-info-text {
    font-size: 14px;
    line-height: 171%;
    letter-spacing: -0.154px;
  }
}
.add-interview-times-container .add-interview-times-inner .main-info-container .main-info-text .blue {
  color: #0D99FF;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .main-info-container .main-info-text br {
    display: none;
  }
}
.add-interview-times-container .add-interview-times-inner .sub-details {
  margin: 0px auto 20px;
  color: #007AFF;
  font-size: 18px;
  width: 100%;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .sub-details {
    font-size: 15px;
  }
}
.add-interview-times-container .add-interview-times-inner .details-container {
  display: flex;
  margin-bottom: 0px;
  width: 100%;
  justify-content: center;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .details-container {
    justify-content: space-between;
  }
}
.add-interview-times-container .add-interview-times-inner .details-container .today-date {
  width: 100px;
}
.add-interview-times-container .add-interview-times-inner .details-container .today-date .today-text {
  background-color: gray;
  color: #FFFFFF;
  border-radius: 4px;
}
.add-interview-times-container .add-interview-times-inner .details-container .today-date .date-text {
  font-size: 30px;
}
.add-interview-times-container .add-interview-times-inner .details-container .current-job-name {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-interview-times-container .add-interview-times-inner .details-container .current-job-name .text {
  color: #FFFFFF;
  background-color: gray;
  padding: 10px 20px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .details-container .current-job-name {
    width: 230px;
    font-size: 13px;
  }
}
.add-interview-times-container .add-interview-times-inner .mobile-add-section {
  display: none;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .mobile-add-section {
    width: 100%;
    padding: 0 16px;
    display: flex !important;
    height: calc(100vh - 200px);
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-calendar {
    width: 100%;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-event {
    padding: 0px 8px !important;
    z-index: 10 !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-event:hover {
    cursor: pointer;
    z-index: 9999 !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group .rbc-time-slot {
    border-top: 0px;
    border-bottom: 0px !important;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group-hover {
    z-index: 9;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group-hover:hover {
    cursor: pointer;
    background: orange;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px !important;
    width: 100%;
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-timeslot-group-hover:hover:before {
    content: "面接枠を入れる";
  }
  .add-interview-times-container .add-interview-times-inner .mobile-add-section .rbc-event-label {
    display: none;
  }
}
.add-interview-times-container .add-interview-times-inner ._loading_overlay_wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}
.add-interview-times-container .add-interview-times-inner .add-section {
  width: 1100px;
  max-width: 100%;
  height: 73vh;
}
@media (max-width: 768px) {
  .add-interview-times-container .add-interview-times-inner .add-section {
    display: none;
  }
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-event {
  z-index: 10 !important;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-event:hover {
  cursor: pointer;
  z-index: 9999 !important;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group .rbc-time-slot {
  border-top: 0px;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group-hover {
  z-index: 9;
  width: 100%;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group-hover:hover {
  cursor: pointer;
  background: orange;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  height: 56px !important;
  justify-content: center;
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-timeslot-group-hover:hover:before {
  content: "面接枠を入れる";
}
.add-interview-times-container .add-interview-times-inner .add-section .rbc-event-label {
  display: none;
}