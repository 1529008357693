.error-modal {
  margin: 100px auto;
  height: 350px;
  margin-left: 220px !important;
}
@media (max-width: 768px) {
  .error-modal {
    height: 450px;
    margin-left: 0 !important;
  }
}
.error-modal .dialog-title {
  text-align: center;
  padding: 10px;
  background-color: #ED5356;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.error-modal .dialog-title .main-close-icon {
  cursor: pointer;
}
.error-modal .dialog-message-container {
  text-align: center;
  min-width: 400px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .error-modal .dialog-message-container {
    max-width: 265px;
    min-width: 265px;
  }
}
.error-modal .dialog-message-container .ok-button {
  border: 2px solid #ED5356;
  color: #ED5356;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px auto;
}
@media (max-width: 768px) {
  .error-modal .dialog-message-container .special-error-message {
    font-size: 10px;
  }
}
.error-modal .dialog-message-container .close-icon-center .error-modal-header-text {
  font-size: 35px;
  color: #ED5356;
}

.new-modal-box {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(90, 90, 90, 0.4705882353);
  z-index: 999;
  backdrop-filter: blur(3px);
}
.new-modal-box .new-modal-container {
  border-radius: 8px;
  border: 1px solid #E9E9E9;
  background: #FFF;
  padding: 26px 0;
  width: 424px;
  max-width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-modal-box .new-modal-container .new-modal-title {
  color: #FF6565;
  font-family: "Noto Sans JP" !important;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.264px;
  margin-top: 16px;
}
.new-modal-box .new-modal-container .new-modal-subtitle {
  color: #1E1E1E;
  text-align: center;
  font-family: "Noto Sans JP" !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.176px;
  margin-top: 11px;
  white-space: pre-wrap;
}
.new-modal-box .new-modal-container .new-modal-cta {
  margin-top: 27px;
  border-radius: 8px;
  background: #33A8ED;
  padding: 10px 24px;
  text-align: center;
  color: #FFF;
  font-family: "Noto Sans JP" !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
}